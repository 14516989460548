<template>
  <span
    @click="$emit('click', $event)"
    class="label"
    :class="{ 'label--small': small, 'label--mini': mini }"
    :style="style"
  >
    <span class="label-bg" :class="bgClass" :style="bgStyle"></span>
    <slot>
      {{ label }}
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    color: {
      type: String,
    },
    primary: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
    mini: {
      type: Boolean,
    },
    bgClass: {
      type: String,
      default: "bg-gray-400",
    },
  },

  computed: {
    style() {
      return {
        "background-color": "transparent",
        cursor: this.clickable ? "pointer" : "inherit",
      }
    },

    bgStyle() {
      return {
        "background-color": this.primary ? "#0f55eb" : this.color,
      }
    },
  },
}
</script>

<style>
.label {
  font-size: 13px;
  letter-spacing: 0.1px;
}

.label--small {
  font-size: 11px;
  padding: 0 4px;
  height: 16px;
  line-height: 16px;
  vertical-align: text-top;
}
.label--mini {
  height: 14px !important;
  line-height: 14px !important;
  padding: 0 3px !important;
  font-size: 10px !important;
}
</style>